import React, { Suspense, useEffect } from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";

import "@sipp/tangerine/dist/cjs/index.css";
import 'antd/dist/antd.less';
import "./index.less";

import AppHeader from "src/Components/AppHeader";
import AppFooter from "src/Components/AppFooter";

import { NAVIGATION_ROUTES } from "./Navigation";
import { Spin } from "antd";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const root = document.getElementById("root");

const App = (
  <React.StrictMode>
    <Router>
      <ScrollToTop />
      <AppHeader />
      <Suspense fallback={<Spin className="spin-fallback" />}>
        <Routes>
          {Object.values(NAVIGATION_ROUTES).map((route) => (
            <Route key={route.path} {...route} />
          ))}
        </Routes>
      </Suspense>
      <AppFooter />
    </Router>
  </React.StrictMode>
);

const reactRoot = createRoot(root);
reactRoot.render(App);