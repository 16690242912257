import React from "react";
import QR from "src/Assets/Icons/ShopeeAppQR/QR.png";
import "./Style/index.less";
import { Footer, ShopeePayIcon } from "@sipp/tangerine";
import {
  SOCIAL_MEDIA_CONTENT,
  DOWNLOAD_CONTENT,
  SERVICE_CONTENT,
  EXPLORE_CONTENT,
  COPYRIGHT_CONTENT,
} from "./Constants";

const footerProps = {
  footerIcon: <ShopeePayIcon />,
  social: SOCIAL_MEDIA_CONTENT,
  download: DOWNLOAD_CONTENT,
  service: SERVICE_CONTENT,
  explore: EXPLORE_CONTENT,
  copyright: COPYRIGHT_CONTENT,
  qrCode:
    <a href="https://shopee.ph/web" className="qr-code">
      <img src={QR} alt="PH QR code" />
    </a>,
}

const AppFooter = () => {
  return (
    <Footer {...footerProps} />
  );
};

export default AppFooter;